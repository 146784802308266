
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import GeoSubDistrictModal from "@/components/modals/forms/settings/SubDistrictModel.vue";
import GeoGridModal from "@/components/modals/forms/settings/GridModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { VueCookieNext } from "vue-cookie-next";

// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "grid",
  components: {
    Form,
    Field,
    Datatable,
    GeoSubDistrictModal,
    GeoGridModal,
  },
  data() {
    return {
      district: "" as any,
      division: "" as any,
      districts: [] as any,
      divisions: [] as any,
      lists: [] as any,

      componentKey: 0,
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "130px",
        },
        {
          name: "sl",
          key: "sl",
          sortable: true,
        },
        {
          name: "District",
          key: "geo_district_id",
          sortable: true,
        },
        {
          name: "Upazila",
          key: "geo_upazila_id",
          sortable: true,
        },
        {
          name: "Grid",
          key: "grid_name",
          sortable: true,
        },
        {
          name: "Grid Size",
          key: "grid_size",
          sortable: true,
        },
        {
          name: "Grid Size Unit",
          key: "grid_size_unit",
          sortable: true,
        },
      ],
      showdatatable: false,
      load: true,
      search: "",

      data: {},
    };
  },
  async created() {
    await this.geoGrid();
    await this.geoDivision();

    this.emitter.on("grid-updated", async () => {
      await this.geoGrid();

      this.componentKey += 1;
    });
  },
  methods: {
    async geoGrid() {
      this.load = true;
      await ApiService.get("configurations/geo_grid/list")
        .then((response) => {
          this.load = false;
          this.lists = response.data.data;

          this.showdatatable = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async filterUpazila() {
      this.load = true;
      await ApiService.get(
        "configurations/geo_upazila/list?division=" +
          this.division +
          "&district=" +
          this.district
      )
        .then((response) => {
          this.lists = response.data.data;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async geoDivision() {
      this.load = true;
      await ApiService.get("configurations/geo_division/list")
        .then((response) => {
          this.load = false;

          this.divisions = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async geoDistrict(division) {
      this.load = true;
      await ApiService.get(
        "configurations/geo_district/list?division=" + this.division
      )
        .then((response) => {
          this.load = false;

          this.districts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // searchItems() {
    //   if (this.search !== "") {
    //     let results: never[] = [];
    //     for (let j = 0; j < this.tableData.length; j++) {
    //       if (this.searchingFunc(this.tableData[j], this.search)) {
    //         results.push(this.tableData[j]);
    //       }
    //     }
    //     this.tableData.splice(0, this.tableData.length, ...results);
    //   } else {
    //     Object.assign(this.tableData, this.lists);
    //   }
    // },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      data.componenyKey = this.componentKey;
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },

    view(subDistrict) {
      this.data = subDistrict;
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(
            "configurations/geo_grid/delete?id=" +
              id
          )
            .then((response) => {
              if (response.data.status == "success") {
                this.emitter.emit("grid-updated", true);
                Swal.fire("Deleted!", response.data.message, "success");
              } else {
                Swal.fire("Error!", response.data.message, "error");
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
